<template>
  <div class="container">
    <div
      style="
        height: 100px;
        padding-left: 20px;
        display: flex;
        align-items: center;
      "
    >
      <span style="font-size: 13px; color: #666">搜索用户：</span>
      <el-input
        placeholder="请输入用户来源"
        v-model="hospitalfullname"
        style="width: 280px"
        clearable
      >
      </el-input>
      <el-input
        placeholder="请输入手机号"
        v-model="mobileno"
        style="width: 280px; margin-left: 20px"
        clearable
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchList"
        ></el-button>
      </el-input>

      <!-- <span style="font-size: 13px; color: #666">手机号：</span>
      <el-input
        placeholder="请输入手机号"
        v-model="mobileno"
        style="width: 280px"
      >
      </el-input> -->
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      size="small"
      style="width: 100%"
    >
      <!-- 用户来源 -->
      <el-table-column type="index" label="#" width="50"> </el-table-column>
     <el-table-column prop="usercode" label="用户编号" width="160">
      </el-table-column>
      <el-table-column prop="mobileno" label="手机号" width="100">
      </el-table-column>
      <el-table-column prop="hospitalfullname" label="用户来源" width="250">
      </el-table-column>
      <el-table-column
        prop="pregnancytypename"
        label="当时怀孕时期"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="reportcount" label="报告数量" width="80">
      </el-table-column>
      <el-table-column prop="email" label="邮箱地址" width="160">
      </el-table-column>
      <el-table-column prop="createtime" label="注册时间" >
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="230">
        <template slot-scope="scope">
          <el-button
            @click="viewDetails(scope.row)"
            type="primary"
            plain
            size="small"
            >基本信息</el-button>
          <el-button
              @click="viewUserProfile(scope.row)"
              plain
              size="small"
          >电子档案</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-table
        v-loading="loading"
        :data="tableData"
        border
        size="small"
        style="width: 100%">
      <el-table-column
          type="index"
          label="#"
          width="50">
      </el-table-column>
      <el-table-column
          prop="username"
          label="用户昵称"
          width="180">
      </el-table-column>
      <el-table-column
          prop="openid"
          label="openid"
          width="280">
      </el-table-column>
      <el-table-column
          prop="mobileno"
          label="手机号码"
          width="180">
      </el-table-column>
      <el-table-column
          prop="hospitalfullname"
          label="绑定医院"
          width="180">
      </el-table-column>
      <el-table-column
          prop="createtime"
          label="注册时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱地址"
          min-width="220">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="220">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" type="primary" plain size="small">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table> -->
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>
    <el-dialog
      append-to-body
      title="用户信息"
      :visible.sync="viewDetailDialog"
      width="700px"
    >
      <UserReportDetails :user-data="viewDetailObj"></UserReportDetails>
    </el-dialog>
    <el-dialog
        append-to-body
        title="电子档案"
        v-if="viewUserProfileDialog"
        :visible.sync="viewUserProfileDialog"
        @before-close="onProfileDialogClose"
        width="850px"
    >
      <UserProfile :user-data="viewProfileObj"></UserProfile>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api";
import UserReportDetails from "../../components/ViewWXUserDetails";
import UserProfile from "@/views/statement/UserProfile";

export default {
  components: { UserReportDetails,UserProfile },
  data() {
    return {
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      tableData: [],
      viewDetailDialog: false,
      viewUserProfileDialog: false,
      viewDetailObj: null,
      loginData: null,
      viewProfileObj: null,
      hospitalfullname: null,
      loading: false,
      mobileno: "",
    };
  },
  watch: {
    hospitalfullname() {
      this.initList();
    },
    mobileno(value) {
      if (value == "") {
        this.initList();
      }
    },
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getCUserPageList({
          hospitalfullname: this.hospitalfullname,
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          page: this.pageIndex,
          pageSize: this.pageSize,
          mobileno: this.mobileno,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    searchList() {
      this.pageIndex = 1;
      this.initList();
    },
    viewDetails(row) {
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
    },
    viewUserProfile(row) {
      this.viewProfileObj = row;
      this.viewUserProfileDialog = true;
    },
    onProfileDialogClose() {
      this.viewProfileObj = null;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>