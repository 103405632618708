<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">用户编号</div>
        <div class="value">
          {{ userData.usercode ? userData.usercode : "无" }}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">用户昵称</div>
        <div class="value">
          {{ userData.username ? userData.username : "无" }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">用户姓名</div>
        <div class="value">
          {{ userData.userfullname ? userData.userfullname : "无" }}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">电子邮箱</div>
        <div class="value">
          {{ userData.email ? userData.email : "无" }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">年龄</div>
        <div class="value">
          {{ userData.age ? userData.age : "无" }}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">手机号码</div>
        <div class="value">
          {{ userData.mobileno ? userData.mobileno : "无" }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="label">openid</div>
        <div class="value">
          {{ userData.openid ? userData.openid : "无" }}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label">注册时间</div>
        <div class="value">
          7
          {{ userData.createtime ? userData.createtime : "无" }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20"> </el-row>
    <el-row :gutter="20"> </el-row>
    <el-row :gutter="20"> </el-row>
    <el-row :gutter="20"> </el-row>
  </div>
</template>4

<script>
export default {
  props: {
    userData: Object,
    edit: Boolean,
  },
};
</script>

<style scoped>
.container {
}
.label {
  line-height: 30px;
  color: #303133;
}
.value {
  line-height: 30px;
  color: #909399;
}
</style>